import React from 'react';
import { Layout } from '../../../components/Layout';

const PlotPlans = () => (
  <Layout
    title="Accurate Plot Plans for Construction | Calgary and Red Deer Survey Experts"
    description="Axiom Geomatics provides precise plot plans to guide your construction projects. Trust Calgary's survey specialists for your property planning needs."
    keywords="plot plans, new home approval, municipal survey, construction survey, bylaw, setback bylaw, house setback, garage"
  >
    <main>
      <h1>Plot Plans</h1>
      <p>
        A Plot Plan is the design diagram showing where proposed buildings (house, garage, …),{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/main-floor-and-footing-elevation-surveys">
          lot elevations
        </a>
        , and{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/utility-construction-surveys">
          utility right-of-ways
        </a>{' '}
        are in relation to property boundaries. Plot plans are generally required to be submitted
        and approved by municipalities before construction begins.
      </p>

      <p>
        The Plot Plan is one of the first steps required in the home building process and often
        accompanies the request for a Development Permit.
      </p>

      <p>
        Plot plans must indicate that the development on the property will be compliant with local
        bylaws. E.g. some municipalities require a 4-foot setback from the property line whereas
        others require a 6.5-foot setback. If the Plot Plan does not meet municipal requirements it
        will not be approved and construction cannot begin.
      </p>

      <p>
        Generally it is the designer or architect of the project that produces the Plot Plan. If
        needed Axiom Geomatics is able to create the required document so your project can begin.
      </p>
    </main>
  </Layout>
);

export default PlotPlans;
